import { Routes } from '@angular/router';

export const routes: Routes = [
  {
		path: '',
		redirectTo: 'login',
		pathMatch: 'full',
	},
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.routes').then((m) => m.routes),
  },
  {
    path: 'register',
    loadComponent: () => import('./pages/auth_old/register/register.page').then( m => m.RegisterPage)
  },
  {
    path: 'loginlegacy',
    loadComponent: () => import('./pages/auth_old/login/login.page').then( m => m.LoginPage)
  },
  {
    path: 'forgot',
    loadComponent: () => import('./pages/auth_old/forgot/forgot.page').then( m => m.ForgotPage)
  },
  {
    path: 'step',
    loadComponent: () => import('./pages/auth_old/step/step.page').then( m => m.StepPage)
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/auth/login/login.page').then( m => m.LoginPage)
  },

];
