import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  provideAnalytics,
  getAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { provideAuth, getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { provideDatabase, getDatabase } from '@angular/fire/database';
import {
  provideFirestore,
  getFirestore,
  connectFirestoreEmulator,
} from '@angular/fire/firestore';
import {
  provideFunctions,
  getFunctions,
  connectFunctionsEmulator,
} from '@angular/fire/functions';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { providePerformance, getPerformance } from '@angular/fire/performance';
import {
  provideRemoteConfig,
  getRemoteConfig,
} from '@angular/fire/remote-config';
import {
  provideStorage,
  getStorage,
  connectStorageEmulator,
} from '@angular/fire/storage';

if (environment.production) {
  enableProdMode();
}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(
      
      IonicModule.forRoot({}),
      provideFirebaseApp(() => {
        const app = initializeApp(environment.firebase);
        return app;
      }),
      // provideAnalytics(() => getAnalytics()),
      provideAuth(() => {
        const auth = getAuth();
        if (environment.emulator) {
          connectAuthEmulator(auth, 'http://localhost:9099');
        }
        return auth;
      }),
      provideFirestore(() => {
        const firestore = getFirestore();
        if (environment.emulator) {
          connectFirestoreEmulator(firestore, 'localhost', 8080);
        }
        return firestore;
      }),
      provideFunctions(() => {
        const functions = getFunctions();
        if (environment.emulator) {
          connectFunctionsEmulator(functions, 'localhost', 5001);
        }
        return functions;
      }),
      // provideMessaging(() => getMessaging()),
      // providePerformance(() => getPerformance()),
      // provideRemoteConfig(() => getRemoteConfig()),
      provideStorage(() => {
        const storage = getStorage();
        if (environment.emulator) {
          connectStorageEmulator(storage, 'localhost', 9199);
        }
        return storage;
      })
    ,
      HttpClientModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      })
    ),
    provideRouter(routes),
  ],
});
